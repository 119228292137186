import { Link } from "react-router-dom";
import PictureBlog00A from "./Media/Uppstuk_Blogg_1024x683px_1-min.jpg";
import PictureBlog0A from "./Media/Uppstuk_Blogg_1024x683px_2-min.jpg";
import PictureBlog0B from "./Media/brain-drain.jpeg";
import PictureBlog1 from "./Media/adult-audience-back-view-1493371-1.jpg";
import PictureBlog2 from "./Media/aerial-desk-device-1011329.jpg";
import PictureBlog3 from "./Media/pexels-photo-2740956.jpg";
import PictureBlog4 from "./Media/brainstorming-colleagues-conversation-1260308.jpg";
import PictureBlog5 from "./Media/adult-advice-analysis-1050297.jpg";
import PictureBlog6 from "./Media/photo-of-led-signage-on-the-wall-942317.jpg";
import PictureBlog7 from "./Media/man-and-woman-discussing-and-sharing-ideas-2977565.jpg";
import PictureBlog8 from "./Media/people-sitting-around-brown-wooden-table-under-white-pendant-1181435.jpg";
import PictureHome1A from "./Media/Professionell-kvinna-söker-jobb-1024x683.jpg";
import PictureHome1B from "./Media/Företag-söker-medarbetare.jpg";
import PictureHome2A from "./Media/abandoned-forest-hd-wallpaper-34950.jpg";
import PictureHome2B from "./Media/Uppstuk-är-ett-företag-i-Uppsala.jpg";
import PictureJobs1A from "./Media/adult-b-blur-927022.jpg";
import PictureCandidates1A from "./Media/adeolu-eletu-lWVoW8LpcEQ-unsplash.jpg";
import PictureCandidates2A from "./Media/architecture-bricks-buildings-cobblestone-461832.jpg";
import PictureCandidates3A from "./Media/Starta-din-karriar-via-Uppstuk-1024x683.jpg";
import PictureCandidates3B from "./Media/photo-of-woman-using-her-laptop-935756.jpg";
import PictureCandidates4A from "./Media/graphs-job-laptop-papers-590016.jpg";
import PictureCandidates4B from "./Media/two-woman-chatting-1311518.jpg";
import PictureCompanies1A from "./Media/team-hos-ett-foretag-Uppstuk-erbjuder-rekrytering.jpg";
import PictureCompanies2A from "./Media/reflection-of-buildings-on-body-of-water-1121782.jpg";
import PictureCompanies3A from "./Media/Glad-kvinna-Foretag-Hyr-Konsult-1024x605.jpg";
import PictureCompanies3B from "./Media/man-having-a-phone-call-in-front-of-a-laptop-859264.jpg";
import PictureContactForm from "./Media/antenna-contact-dawn-dusk-33153.jpg";
import PictureAbout1A from "./Media/pexels-photo-207569.jpg";
import PictureAbout2A from "./Media/alphabet-creativity-cube-letter-462353.jpg";
import PictureAbout2B from "./Media/accomplishment-ceremony-education-graduation-267885.jpg";
import PictureFacebook from "./Media/facebook-3-xxl.png";
import PictureLinkedIn from "./Media/linkedin-3-xxl.png";
import PictureInstagram from "./Media/instagram-xxl.png";
import PictureLogo from "./Media/Uppstuk_Logo_2019_Vit_Medium.png";
import PictureReview1 from "./Media/Dennis_konsult_Uppstuk.jpg";
import PictureReview2 from "./Media/Ebba-konsult-Uppstuk.jpg";
import PictureReview3 from "./Media/partner1.png";
import PictureReview4 from "./Media/partner3.jpg";
import PicturePartner1 from "./Media/partner1.png";
import PicturePartner2 from "./Media/partner2.png";
import PicturePartner3 from "./Media/partner3.jpg";
import PicturePartner4 from "./Media/partner4.png";
import PictureBusiness1 from "./Media/Stock.jpg";
import PictureBusiness2 from "./Media/louis-reed-pwcKF7L4-no-unsplash.jpg";
import PictureBusiness3 from "./Media/simon-abrams-k_T9Zj3SE8k-unsplash.jpg";
import PictureBusiness4 from "./Media/campaign-creators-qCi_MzVODoU-unsplash.jpg";
import PictureBusiness5 from "./Media/Uppstuk-HR.jpg";
import PictureBusiness6 from "./Media/corinne-kutz-tMI2_-r5Nfo-unsplash.jpg";
import PictureBusiness7 from "./Media/anastasia-zhenina-E4XARZNDjzI-unsplash.jpg";
import PictureBusiness8 from "./Media/patrick-lindenberg-1iVKwElWrPA-unsplash.jpg";
import PictureAdamW from "./Media/adam-westerling.webp";
import PictureAlexanderW from "./Media/alexander-westford.webp";
import PictureLudvigA from "./Media/ludvig-alden.webp";

export const notFound = "Ojdå, något gick fel!";

export const cookies = {
  consentText:
    "Vi använder cookies för att ge dig en bättre användarupplevelse.",
  accept: "Jag accepterar cookies",
  decline: "Jag nekar användandet av cookies"
};

export const menu = {
  top: [
    { button: "", link: "" },
    { button: "", link: "" },
    { button: "", link: "" },
    { button: "Kontakt", link: "/kontakt" },
    { button: "Om oss", link: "/om-uppstuk" }
  ],
  bottom: [
    { button: "Hem", link: "/" },
    { button: "Lediga jobb", link: "/jobb" },
    { button: "Kandidat", link: "/kandidat" },
    { button: "Företag", link: "/foretag" },
    { button: "Blogg", link: "/nyheter" }
  ]
};

export const bannerTitleJobs = {
  text1: "Många inriktningar",
  text2: "Sök lediga jobb"
};

export const bannerTitleCandidates3 = {
  text1: "Vad Uppstuk gör",
  text2: "Vårt erbjudande"
};

export const bannerTitleCandidates4 = {
  text1: "Tips från",
  text2: "Våra experter"
};

export const bannerTitleCompanies3 = {
  text1: "Använd er av Uppstuk",
  text2: "Vårt erbjudande"
};

export const bannerTitleBlog = {
  text1: "Från vår",
  text2: "Blogg"
};

export const bannerTitleAbout3 = {
  text1: "Om oss",
  text2: "Vår Mission"
};

export const jobsButton = {
  text1: "Alla",
  text2: "Se alla våra lediga tjänster",
  text4: "Ansök nu"
};

export const cookieAlert = {
  text1: "Fortsätter blockera cookies."
};

export const homeBanner1A = {
  text1: "Jag är en kandidat som",
  text2: "Söker jobb",
  text3: "Hitta ditt nästa jobb via Uppstuk",
  text4: "Sök jobb",
  link1: "/jobb",
  center: "center",
  theProps: {
    cn: "homeBanner1A",
    picture: PictureHome1A,
    color: "blue",
    half: "481"
  }
};

export const homeBanner1B = {
  text1: "Vi är ett företag som",
  text2: "Söker kollegor",
  text3: "Hitta era medarbetare via Uppstuk",
  text4: "Sök kollegor",
  link1: "/foretag",
  center: "center",
  theProps: {
    cn: "homeBanner1B",
    picture: PictureHome1B,
    color: "grey",
    half: "481"
  }
};

export const homeBanner1 = {
  first: homeBanner1A,
  second: homeBanner1B,
  theProps: {
    cn: "homeBanner1",
    first: "first",
    parent: "parent"
  }
};

export const homeBanner2A = {
  text1: "Om oss",
  text2: "Vilka är Uppstuk?",
  text3:
    "Uppstuk är ett bemannings- och rekryteringsföretag i Uppsala som matchar företag med akademiker från ett 360°-perspektiv, ett erbjudande vi kallar flexkompetens. Vi erbjuder konsulter inom flera olika branscher och våra konsulter finns tillgängliga de perioder då ni behöver säkra upp med extra resurser. Flexa med Uppstuk.",
  text4: "Om oss",
  link1: "/om-uppstuk",
  justify: "1025",
  theProps: {
    cn: "homeBanner2A",
    picture: PictureHome2A,
    color: "blue",
    half: "961"
  }
};

export const homeBanner2B = {
  theProps: {
    cn: "homeBanner2B",
    picture: PictureHome2B,
    half: "961"
  }
};

export const homeBanner2 = {
  first: homeBanner2A,
  second: homeBanner2B,
  theProps: {
    cn: "homeBanner2",
    parent: "parent"
  }
};

export const jobsBanner1 = {
  text1: "Hitta ditt drömjobb bland våra lediga tjänster",
  text2: "Lediga jobb",
  center: "center",
  theProps: {
    cn: "jobsBanner1",
    picture: PictureJobs1A,
    color: "blue",
    first: "first"
  }
};

export const candidatesBanner1 = {
  text1: "Starta din karriär via",
  text2: "Uppstuk",
  text3: "Hitta din framtida arbetsplats bland Uppsalas bästa arbetsgivare",
  text4: "Sök jobb",
  link1: "/jobb",
  center: "center",
  theProps: {
    cn: "candidatesBanner1",
    first: "first",
    picture: PictureCandidates1A,
    color: "blue"
  }
};

export const candidatesBanner2 = {
  text1: "Om oss",
  text2: "Vilka är Uppstuk?",
  text3:
    "Uppstuk är ett bemannings– och rekryteringsföretag i Uppsala som matchar akademikers kompetens och erfarenhet, med spännande och innovativa företag. Vi är verksamma inom flera olika branscher som bland annat IT, Life science och Ekonomi och kan erbjuda både juniora och mer seniora uppdrag. Oavsett om du fortfarande studerar eller hunnit ta examen och letar efter nya utmaningar, kan vi hjälpa dig. Vi erbjuder flexkompetens, vilket är konsultuppdrag som passar med ditt schema och önskemål. Dessa varierar från några timmar i veckan, deltid och upp till heltid. Vi kan också vara din genväg till drömjobbet och ge dig foten in på ett företag genom direktrekrytering.",
  text4: "Om oss",
  text5: "Kontakt",
  link1: "/om-uppstuk",
  link2: "/kontakt",
  special: "columns",
  center: "center",
  theProps: {
    cn: "bannerColumns",
    picture: PictureCandidates2A,
    color: "blue"
  }
};

export const candidatesBanner3A = {
  text1: "Vad erbjuder Uppstuk?",
  text2: "Uppstuk är starten på din karriär",
  text3:
    "Vi matchar företag med studenter och utexaminerade från universitetsvärlden.",
  text4: "Kontakt",
  link1: "/kontakt",
  justify: "1025",
  theProps: {
    cn: "candidatesBanner3A",
    picture: PictureCandidates3A,
    color: "blue",
    half: "641"
  }
};

export const candidatesBanner3B = {
  text1: "Hur ser processen ut?",
  text2: "Så fungerar det",
  text3:
    "Nyfiken på att få veta mer hur du går tillväga för att bli rekryterad som konsult eller direkt till ett företag?",
  text4: "Processen",
  text5: "Bli konsult",
  link1: "/nyheter/hur-det-fungerar",
  link2: "/jobb",
  justify: "1025",
  theProps: {
    cn: "candidatesBanner3B",
    picture: PictureCandidates3B,
    color: "grey",
    half: "641"
  }
};

export const candidatesBanner3 = {
  first: candidatesBanner3A,
  second: candidatesBanner3B,
  theProps: {
    cn: "candidatesBannerThree",
    parent: "parent"
  }
};

export const candidatesBanner4A = {
  text1: "Inför ansökan",
  text2: "Tips för ett bra CV",
  text3:
    "Med erfarenhet av både jobbsökande och rekryterande part har vi nedan listat våra bästa tips för att hjälpa dig att göra ditt CV så bra som möjligt!",
  text4: "CV-Tips",
  link1: "/nyheter/cv",
  justify: "1025",
  theProps: {
    cn: "candidatesBanner4A",
    picture: PictureCandidates4A,
    color: "blue",
    half: "481"
  }
};

export const candidatesBanner4B = {
  text1: "Inför intervjun",
  text2: "Tips för en bra intervju",
  text3:
    "Intervjun är ett bra tillfälle för dig som kandidat och för företaget att lära känna varandra.",
  text4: "Inför intervjun",
  link1: "/nyheter/tips-for-intervju",
  justify: "1025",
  theProps: {
    cn: "candidatesBanner4B",
    picture: PictureCandidates4B,
    color: "grey",
    half: "481"
  }
};

export const candidatesBanner4 = {
  first: candidatesBanner4A,
  second: candidatesBanner4B,
  theProps: {
    cn: "candidatesBanner4",
    parent: "parent"
  }
};

export const companiesBanner1 = {
  text1: "Vi är din rekryteringspartner",
  text2: "Hitta nya talanger i vårt breda nätverk",
  text3:
    "Oavsett vilket personalbehov du har hittar vi en lösning som passar just dig",
  text4: "Sök medarbetare",
  link1: "/kontakt",
  center: "center",
  theProps: {
    cn: "companiesBanner1",
    first: "first",
    picture: PictureCompanies1A,
    color: "blue"
  }
};

export const companiesBanner2 = {
  text1: "Om oss",
  text2: "Vilka är Uppstuk?",
  text3:
    "Uppstuk är ett bemannings- och rekryteringsföretag som matchar företag med akademiker. Vi har en stark förankring i universitetsvärlden och bland våra ägare finns Uppsala universitet genom UU Holding AB, Uppsala teknolog- och naturvetarkår samt Uppsalaekonomerna, vilket ger oss ett brett nätverk med både juniora och seniora profiler. Med vår tjänst flexkompetens kan du få hjälp när du behöver det som mest. Vid arbetstoppar eller över en längre tid kan du hyra in konsulter, med en omfattning från några timmar i veckan upp till heltid. Önskar du istället få hjälp med att anställa din nästa stjärna kan du använda dig av direktrekrytering, så att du kan fokusera på ditt företag istället!",
  text4: "Om oss",
  text5: "Kontakt",
  link1: "/om-uppstuk",
  link2: "/kontakt",
  special: "columns",
  center: "center",
  theProps: {
    cn: "bannerColumns",
    picture: PictureCompanies2A,
    color: "blue"
  }
};

export const companiesBanner3A = {
  text1: "I behov av tillfällig personal?",
  text2: "Hyr en konsult",
  text3:
    "När du söker kvalificerad kompetens kan Uppstuk hjälpa dig, oavsett om det gäller ett kortare eller längre uppdrag, och en junior eller mer senior nivå.",
  text4: "Hyr en konsult",
  text5: "Kontakt",
  link1: "/nyheter/hyr-en-konsult",
  link2: "/kontakt",
  justify: "1025",
  theProps: {
    cn: "companiesBanner3A",
    picture: PictureCompanies3A,
    color: "blue",
    half: "641"
  }
};

export const companiesBanner3B = {
  text1: "Behöver du hjälp?",
  text2: "Rekryteringsstöd",
  text3:
    "Behöver du hjälp med att rekrytera? Utnyttja vårt breda nätverk av såväl juniora som seniora kandidater!",
  text4: "Så kan vi hjälpa er",
  text5: "Kontakt",
  link1: "/nyheter/fa-hjalp-med-rekrytering",
  link2: "/kontakt",
  justify: "1025",
  theProps: {
    cn: "companiesBanner3B",
    picture: PictureCompanies3B,
    color: "grey",
    half: "641"
  }
};

export const companiesBanner3 = {
  first: companiesBanner3A,
  second: companiesBanner3B,
  theProps: {
    cn: "companiesBanner3",
    parent: "parent"
  }
};

export const aboutBanner1 = {
  text1: "Om oss",
  text2: "Vi kompetensförsörjer Uppsala",
  text3:
    "Uppstuk bemannar och rekryterar för ledande företag i Uppsala. Vi har konsulter inom bland annat IT, Life Science & Ekonomi",
  text4: "Kontakt",
  link1: "/kontakt",
  center: "center",
  theProps: {
    cn: "aboutBanner1",
    first: "first",
    picture: PictureAbout1A,
    color: "blue"
  }
};

export const aboutBanner2 = {
  text1: "Historia",
  text2: "Vår resa",
  text3:
    "Idén till Uppstuk föddes 2011 då två studenter på Uppsala universitet såg stora möjligheter att fylla glappet mellan studenterna och näringslivet. Studenterna satt på kunskap, driv och nytänkande men saknade ofta relevant erfarenhet och kontakter vilket försvårade deras möjligheter att få sitt drömjobb. I näringslivet fanns det ett behov av kvalificerad arbetskraft men en svårighet att nå ut till studenter om man inte satt på en stor kassa. Redan från början fanns en stark koppling till universitetet och Uppsala. Verksamheten har sedan starten drivits av både studenter och akademiker, och Uppstuk ägs fortfarande av studentföreningar, universitetet och flera Uppsalaföretag, vilket ger Uppstuk en unik inblick i universitetsvärlden och näringslivet. Idag hjälper vi studenter och duktiga yrkesmänniskor som varit ute i arbetslivet en längre tid att komma vidare i sin karriär.",
  special: "columns",
  center: "center",
  theProps: {
    cn: "bannerColumns",
    picture: PictureAbout2B,
    color: "blue"
  }
};

export const aboutBanner3A = {
  text1: "Historia",
  text2: "Vår mission",
  text3:
    "Vår mission är att föra samman Uppsalas näringsliv med såväl studenter som akademiker och att stärka Uppsalaregionen. Uppstuk erbjuder kandidater möjlighet att få arbetslivserfarenhet samtidigt som Uppsalas näringsliv får tillgång till kompetent och engagerad arbetskraft.",
  justify: "1025",
  theProps: {
    cn: "aboutBanner3A",
    picture: PictureAbout2A,
    color: "blue",
    half: "1281"
  }
};

export const aboutBanner3B = {
  theProps: {
    cn: "aboutBanner3B",
    picture: PictureHome2B,
    half: "1281"
  }
};

export const aboutBanner3 = {
  first: aboutBanner3A,
  second: aboutBanner3B,
  theProps: {
    cn: "aboutBanner3",
    parent: "parent"
  }
};

export const partner = {
  title: {
    cn: "grey",
    text:
      "Uppstuk rekryterar för & samarbetar med många marknadsledande företag"
  },
  picture1: {
    cn: "picture1",
    picture: PicturePartner1,
    link: "https://www.inbiz.se/"
  },
  picture2: {
    cn: "picture2",
    picture: PicturePartner2,
    link: "https://biovica.com"
  },
  picture3: {
    cn: "picture3",
    picture: PicturePartner3,
    link: "https://skandionkliniken.se/"
  },
  picture4: {
    cn: "picture4",
    picture: PicturePartner4,
    link: "http://www.vaknauppsala.se/"
  }
};

export const review1 = {
  cn: "reviewPersonell",
  title: {
    text1: "Vad anställda säger",
    text2: "Recensioner"
  },
  reviews: [
    {
      text: {
        cn: "text1",
        text1:
          '"Som konsult hos Uppstuk har vägen till att uppnå mina mål och min potential banats med oupphörlig stöttning och det råder inga tvivel om att detta sedan första arbetsdagen satt en guldkant på tillvaron!"',
        text2: "- Dennis Johansson"
      },
      picture: { image: PictureReview1, cn: "employee" }
    },
    {
      text: {
        cn: "text2",
        text1:
          '"Att jobba via Uppstuk har gett en fantastiskt bra möjlighet att komma in på ett företag som nyexaminerad, en perfekt övergång mellan studierna och arbetslivet! Man har alltid möjlighet att få hjälp och stöd av personalen på Uppstuk vilket känns bra då man är ny på jobbet."',
        text2: "- Ebba Perman"
      },
      picture: { image: PictureReview2, cn: "employee" }
    }
  ]
};

export const review2 = {
  cn: "reviewPartners",
  title: {
    text1: "Framgångshistorier",
    text2: "Så har vi hjälpt andra företag"
  },
  reviews: [
    {
      text: {
        cn: "text1",
        text1: '"Arbetet tillsammans med Uppstuk har varit extremt smidigt"',
        text2: "-Kajsa Enkvist, VD Företaget"
      },
      picture: { image: PictureReview3, cn: "partner" }
    },
    {
      text: {
        cn: "text2",
        text1:
          '"Samarbetet har gett oss möjligheten att fokusera på det vi ska"',
        text2: "-Anna Eriksson, VD Företaget"
      },
      picture: { image: PictureReview4, cn: "partner" }
    }
  ]
};

export const personell = {
  cn: "personellBanner",
  title: { text1: "Vår personal", text2: "Vi som jobbar här" },
  personell: [
    {
      picture: PictureAlexanderW,
      text1: "Alexander Westford",
      text2: "Affärsutvecklare &\nOperationsansvarig",
      text3: "alexander@uppstuk.se",
      text4: "076 - 778 67 00",
      text5: "LinkedIn",
      link: "https://www.linkedin.com/in/alexander-westford-4abb17223/",
      blurb: "Med en fot i både start-up-världen och studentnationer i Uppsala ligger min drivkraft i att skapa " +
          "synergieffekter genom att koppla ihop studenter och företag."
    },
    {
      picture: PictureAdamW,
      text1: "Adam Westerling",
      text2: "Affärsutvecklare &\nRekryteringsansvarig",
      text3: "adam@uppstuk.se",
      text4: "073 - 523 74 28",
      text5: "LinkedIn",
      link: "https://www.linkedin.com/in/adamskoglund/",
      blurb: "Med över 10 års erfarenhet av rekrytering ligger min motivation i att hitta de där perfekta " +
          "matchningarna när både människor och företag utvecklas och växer. Win-win för alla är grunden " +
          "i all lyckad rekrytering."
    }
  ]
};

export const businessAreas = {
  title1: "Branscher",
  title2: "Affärsområden",
  textArray: [
    "Ekonomi",
    "Life Science",
    "IT",
    "Marknad",
    "HR & Admin.",
    "Grafisk design",
    "Energi",
    "Teknik"
  ],
  pictureArray: [
    PictureBusiness1,
    PictureBusiness2,
    PictureBusiness3,
    PictureBusiness4,
    PictureBusiness5,
    PictureBusiness6,
    PictureBusiness7,
    PictureBusiness8
  ]
};

export const blogButton = "Läs mer";

export const blogBannerGroupTitle = { text1: "Från vår", text2: "Blogg" };

export const blogPosts = [
  [
    {
      titleCategory: "Konsulter",
      title: 'Lösningen på "brain drain" – vi knyter samman studenter och företag',
      author: "Alexander Westford",
      date: "2 maj 2024",
      picture: PictureBlog0B,
      pictureCloudinaryFileName: 'uppstuk_cover_fwon6q.jpg',
      pictureCloudinareAuthorFileName: 'alexander-westford_rbudac.webp',
      link: "/nyheter/brain-drain-losning"
    },
    { bold: `I det föränderliga näringslivet och den dynamiska akademiska miljön uppstår ständigt en utmaning: övergången från universitetet till arbetslivet. För både studenter och företag kan denna innebära osäkerhet och missade chanser.` },
    { text: 'En av de största utmaningarna för universitetsstäder är "brain drain" – begåvade studenter lämnar staden efter examen för att söka karriärmöjligheter på annan ort. Detta påverkar inte bara lokalsamhället negativt, utan utgör också ett problem för företag som vill anlita denna talangpool. Många företag upplever en brist på kompetens hos nyutexaminerade, samtidigt som studenter kämpar för att hitta tjänster som matchar deras kunskaper och ambitioner.' },
    { text: 'På Studentums lista över Sveriges bästa studentstäder från mars 2024 sjönk Uppsala ännu en gång till plats 27 av 34, huvudsakligen på grund av att Uppsala bedöms vara den stad med sämst karriärmöjligheter efter examen i hela landet. Uppsala kommer även på plats 30 i rankningen av möjligheter till extrajobb. Uppstuk tar sig an utmaningen och förnyar sättet för studenter att få tillgång till arbetsmöjligheter under och efter studierna.' },
    { bold: 'Så hur åstadkommer vi detta?' },
    { text: 'Vårt team samarbetar nära universitetet för att hitta lovande studenter och koppla samman dem med företag som söker ny kompetens. Genom samverkan med akademin säkerställer vi att studenterna har rätt kunskaper för att lyckas inom sina områden.' },
    { text: 'Vidare samarbetar vi nära med företag för att förstå deras specifika behov och kultur, vilket möjliggör matchning av kandidater som inte bara har rätt kompetens utan också delar företagets värderingar och vision. Genom ömsesidig förståelse kan vi skapa långsiktiga samarbeten som gynnar båda parter.' },
    { text: 'I grunden är Uppstuk mer än en matchningstjänst – den är en katalysator för utveckling och förnyelse. Genom att ge studenter chansen att nå sin fulla potential och koppla samman företag med topptalanger formar vi om landskapet för studentkonsulting och banar väg för framtiden.' },
  ],
  [
    {
      titleCategory: "Konsulter",
      title: "Gå stärkta framåt – vi har svårfångade kompetenser",
      author: "Niklas Fröling",
      date: "9 april 2020",
      picture: PictureBlog00A,
      link: "/nyheter/vi-har-kompetensen"
    },
    {
      bold: `För den som har möjlighet att satsa på en rekrytering just nu finns mycket att vinna. Vi har många tillgängliga konsulter som sitter på kompetens inom olika branscher och områden, bl.a inom IT-utveckling. När läget stabiliseras gäller det att se till att vara redo och stark på andra sidan – eller hur?`
    },
    { header: `Vi vill underlätta er rekryteringsprocess` },
    {
      text: `Därför vill vi hjälpa till att underlätta er rekrytering med tanke på rådande läge, så att ni kan utgå från hur läget och ert behov ser ut just nu, så att ni kan vara först på gasen inom en snar framtid.`
    },
    { bold: `FLEXIBLA ANSTÄLLNINGSFORMER` },
    {
      text: `Vi har tillgängliga konsulter för ett par dagar i veckan, deltid eller heltid. Kanske hade ni tänkt anställa en ny förmåga men tvekar nu på grund av rådande läge? Då rekommenderar vi att ni börjar med att tillsätta en konsult med en flexibel anställningsform, så att konsulten kommer in i jobbet och är redo att kliva över i anställning när ni vill öka tempot ännu mer.`
    },
    {
      bold: `“Vi vill hjälpa dig som känner att kostnad är det som står i vägen för en nyrekrytering. Därför erbjuder vi just nu nya kunder att dela upp betalningen i tre delbetalningar. “ - Niklas Fröling.`
    },
    { bold: `ONLINELÖSNINGAR FÖR KONTAKT & MÖTEN` },
    {
      text: `Vid en rekryteringsprocess är det mycket kontakt mellan kund och rekryterare och vi vill fortsätta att värna om dessa viktiga möten. Därför erbjuder vi nu onlinelösningar för möten i kombination med en tät dialog genom hela processen för att ge en så trygg rekryteringsprocess som möjligt. Vi tillämpar dessa onlinelösningar både för kundmöten och intervjuer och har väldigt god erfarenhet av hur smidigt och effektivt det är. Vi har varken märkt av någon skillnad i utfall eller intervjukvalitet.`
    },
    {
      text: `Vi kan även ge dig rätt verktyg för att involvera er nya medarbetare på ett så bra sätt som möjligt. I dagens läge är det viktigt att lägga fokus på remote onboarding i de fall det inte är möjligt att göra på plats. Hur långt har ni kommit med era processer för onboarding på distans?`
    },
    {
      bold: `Välkommen att kontakta mig för att prata vidare om vilken typ av rekryteringsprocess och lösning som kan passa just ert företag.`
    }
  ],
  [
    {
      titleCategory: "Onboarding",
      title: "Att lyckas med onboarding på distans",
      author: "Niklas Fröling",
      date: "8 april 2020",
      picture: PictureBlog0A,
      link: "/nyheter/lyckas-med-onboarding"
    },
    {
      bold: `Hur genomförs en onboarding på distans? För många av våra kunder ligger fokus just nu på att minimera produktionsbortfall och bibehålla en så hög företagsamhet som möjligt. Vi vill stötta våra kunder genom att erbjuda Flexkompetens® och bra förutsättningar för en rekryteringsprocess som sker helt eller delvis på distans. Så att vi tillsammans kan rida ut stormen och komma ut starkare på andra sidan.`
    },
    { header: `Effektiva möten på distans` },
    {
      text: `Vi gick tidigt över till att jobba med möten och intervjuer online. På så sätt kan vi hålla viktiga rekryteringsprocesser igång och samtidigt ta nödvändiga försiktighetsåtgärder. Vi har positiv erfarenhet av onboarding på distans och nedan hittar du våra bästa tips för hur du bäst lyckas med en onboardingprocess som sker helt eller delvis på distans.`
    },
    { bold: `Viktigt att tänka på vid onboarding på distans` },
    {
      text: `All onboarding online skall vara strukturerad likt en vanlig onboarding, i den mån tjänsten tillåter. Se över vilka moment som finns i er vanliga onboarding-process och anpassa dem för att fungera på distans. Den största skillnaden är att processen kommer ta något längre tid än vanligt vilket man böra vara medveten om och anpassa onlinemöten och liknande med detta i åtanke.`
    },
    { bold: `Hur genomförs en onboarding på distans?` },
    {
      quote: `Nedan hittar du vår onboardingprocess med steg och parametrar som avgör hur väl du lyckas med din onboarding på distans.`
    },
    { bold: `Ge en bra introduktion - var/när/hur/varför` },
    {
      text: `Det är viktigt att man från början berättar varför man gör en onboarding på distans och hur det kommer gå till. Gör en tydlig checklista med vilka moment som skall genomföras samt vilka fysiska möten som är nödvändiga, om några. Ta hjälp av oss på Uppstuk för att upprätta en bra checklista och process. Nedan beskriver vi de viktigaste stegen för hur du lyckas med onboarding på distans.`
    },
    { bold: `1. UTRUSTNING OCH INLOGGNINGAR` },
    {
      text: `Detta steg bör se ungefär likadant ut som vid vanlig onboarding. Säkerställ checklistan över de moment (dator, inloggningar, informationsdelning, etc) som normalt ingår i er onboarding. Skillnaden är att inloggningar och arbetsverktyg är viktigare än det fysiska skrivbordet. Detta steg kan även ta något längre tid än vanligt.`
    },
    { bold: `2. UTSE EN MENTOR` },
    {
      text: `Det är viktigare än vanligt att den nya medarbetaren vet vem medarbetaren ska vända sig till om denne har frågor eller funderingar. Därför bör en tydlig mentor utses.`
    },
    { bold: `Våra bästa tips` },
    {
      text: `- Det viktigaste är att välja en person med integritet, lugn och kunskap om teamet.`
    },
    {
      text: `- Mentorn ska hålla tät dialog med den nya medarbetaren om arbetssituationen och känslan i företaget(även på distans)`
    },
    {
      text: `- Mentorn är den som ska vägleda och se till att den nyanställda kommer igång med sina arbetsuppgifter.`
    },
    { bold: `3. INVOLVERA MEDARBETAREN I TEAMET` },
    {
      text: `Hur skall det gå när den nya medarbetaren kanske inte träffar någon av sina nya kollegor IRL? Det viktigaste här är att göra så att er nya medarbetare känner sig välkommen i gruppen.`
    },
    { bold: `Våra bästa tips` },
    {
      text: `- Var informell och personlig, det skapar tillit till teamkulturen.`
    },
    {
      text: `- Boka in ett välkomstmöte över nätet där de närmaste medarbetarna presenterar sig och får höra mer om sin nya teammedlem.`
    },
    {
      text: `- Skapa en chattgrupp, Slack eller motsvarande där medarbetarna kan kommunicera med varandra och involvera sin nya kollega snabbt och informellt. Faktatunga meddelanden kan gå på mail för sortering.`
    },
    { header: `När personen är 'på plats'` },
    { bold: `4. INFORMATION & KOMMUNIKATION` },
    {
      text: `Kommunikation är något av det viktigaste vid onboarding, även på distans. Det är otroligt viktigt att medarbetaren/konsulten får rätt information om företaget och om sina arbetsuppgifter.`
    },
    { bold: `Undvik fallgropar` },
    {
      text: `- Skicka inte företagets årsredovisning med medföljande presentation eller andra dokument utan förklaring eller kommentar. Slå en pling och berätta varför du skickat över materialet och vad som är viktigt.`
    },
    {
      text: `- Sammanställ så mycket information kring arbetsuppgifter och teammedlemmar som möjligt för att underlätta.`
    },
    {
      text: `- Den enda risken med onboarding på distans är att man missar små nyanser i kommunikation som normalt sker ansikte mot ansikte. Öppna upp för dialog och en ärlig kommunikation så kan ni undvika detta problem.`
    },
    { bold: `5. ÅTERKOPPLING & FEEDBACK` },
    {
      text: `Många företag tenderar att omedvetet prioritera bort återkoppling, och detta är ett bra tillfälle att vässa rutinerna för detta och se till att de fungerar på ett bra sätt. Det gäller både kommunikation till och från medarbetaren och mentor, chef och team. Det är viktigt med återkoppling och feedback för att säkerställa att den nya medarbetaren förstår uppdraget och sina arbetsuppgifter så att dessa kan utföras på bästa sätt.`
    },
    { bold: `VAR PERSONLIG, DRICK KAFFE OCH HA KUL TILLSAMMANS!` },
    {
      text: `Slutligen vill vi pusha för arbetskulturen. På kontoret pratas det inte bara om jobb, detsamma borde gälla på distans. “God morgon” kan sägas över chatten. “Vi ses imorgon, ha en fin kväll” likaså.`
    },
    {
      text: `Detta är extra viktigt när man är ny på kontoret. Kanske dags att boka in en stående kaffe-chat varje torsdag? Det är viktigt att stärka teamkänslan och kulturen på distans och kanske stärker ni er kultur ytterligare genom att nu tillsammans ta er an de nya utmaningar som uppstår med att arbeta på distans.`
    },
    {
      bold: `Känner du att detta känns övermäktigt eller vill du ha stöd med denna process? Välkommen att kontakta mig.`
    }
  ],
  [
    {
      titleCategory: "Rekrytering",
      title: "Vad innebär det att bli rekryterad?",
      author: "Malin Johansson",
      date: "30 november 2019",
      picture: PictureBlog1,
      link: "/nyheter/rekrytering"
    },
    {
      quote: `Har du höga ambitioner och mål med din karriär? Låt oss på Uppstuk bli din genväg till drömjobbet! Vi matchar din unika kompetens och erfarenhet med attraktiva företag på arbetsmarknaden. Med hjälp av oss kan du samla på dig arbetslivserfarenhet, stärka ditt CV och bygga upp ett större professionellt kontaktnät på de arbetsplatser du jobbar på. Är du nyfiken på att veta mer om hur vi kan hjälpa dig? Läs mer om fördelarna av att bli rekryterad nedan!`
    },
    {
      text: `Oavsett om du precis har tagit examen eller är ute i arbetslivet och söker nya utmaningar kan vi hjälpa dig att ta nästa steg i karriären! Genom att använda dig av Uppstuks rekrytering kan du stärka ditt CV och ta en genväg till dröm­jobbet.`
    },
    { header: `Hur går det till?` },
    {
      text: `Vid en rekrytering hjälper vi företagen att hitta rätt kompetens. Detta innebär att vi söker löpande både juniora och seniora profiler inom flera områden, och du blir med vår hjälp direkt anställd hos ett spännande företag. Processen sköts av oss och det är vi på Uppstuk som intervjuar dig, sköter kommunikationen, och tar referenser. `
    },
    {
      text: `Därefter presenteras du för arbetsgivaren, och om du erbjuds tjänsten sker avtalsskrivning direkt med företaget. För att säkerställa att du trivs hos din nya arbetsgivare finns vi även kvar som stöttning under de första 6 månaderna i din anställning. Vårt mål är alltid att hjälpa dig ta nästa steg i karriären, och rusta inför din framtid! `
    },
    {
      text: `Ta första steget mot att bli en del av oss genom att registrera dig i vårt kandidatnätverk eller sök någon av våra lediga tjänster nedan. Fanns inte drömjobbet ute just nu? Tryck här för att registrera din profil och få uppdateringar på när nya tjänster finns tillgängliga. Vi får löpande in förfrågningar från företag som söker nya talanger så håll utkik!`
    },
    {
      text: `Är du nyfiken på att höra mer om hur processen går till? Kontakta oss för mer information!`
    },
    { bold: `Välkommen till oss önskar teamet på Uppstuk!` }
  ],

  [
    {
      titleCategory: "Konsulter",
      title: "Att jobba som konsult",
      author: "Malin Johansson",
      date: "11 november 2019",
      picture: PictureBlog2,
      link: "/nyheter/konsult"
    },
    {
      quote: `Har du höga ambitioner och mål med din karriär? Sök till våra konsultuppdrag! Som konsult hos Uppstuk kommer du samla på dig arbetslivserfarenhet, stärka ditt CV och bygga upp ett större professionellt kontaktnät på de arbetsplatser du jobbar på. Är du nyfiken på att veta mer om hur vi kan hjälpa dig? Läs mer om fördelarna av att vara konsult nedan!`
    },
    {
      quote: `”Som konsult hos Uppstuk har vägen till att uppnå mina mål och min potential banats med oupphörlig stöttning och det råder inga tvivel om att detta sedan första arbetsdagen satt en guldkant på tillvaron!”`
    },
    { quote: `Dennis Johansson – IT konsult hos Q-Linea` },
    {
      text: `Våra konsultuppdrag kan anpassas till dig oavsett om du studerar, precis tagit examen eller är ute i arbetslivet och söker nya utmaningar. Vi erbjuder både mer juniora och seniora uppdrag, som varierar i omfattning mellan några timmar i veckan och upp till heltid. För oss är det viktigt att matcha din unika kompetens med attraktiva företag på arbetsmarknaden, vilket vi gör inom flera områden som bland annat IT, Life science, ekonomi och HR/administration för att nämna några.`
    },
    {
      text: `Vårt mål är alltid att hjälpa dig ta nästa steg i karriären, och rusta inför din framtid! Vid sidan om lön och andra anställningsvillkor, får du hos oss en dedikerad konsultchef som hjälper dig genom rekryteringsprocessen, men även under uppdragets gång för att se till att du hittar rätt arbetsplats och rätt tjänst som passar din erfarenhet och kompetens. Att arbeta som konsult hos oss kan vara din genväg till drömjobbet; många av våra konsulter blir erbjudna en fast tjänst hos de företag de har jobbat på under tiden som konsult!`
    },
    {
      text: `Ta första steget mot att bli en del av oss genom att registrera dig i vårt kandidatnätverk eller sök någon av våra lediga tjänster nedan. Fanns inte drömjobbet ute just nu? Tryck här för att registrera din profil och få uppdateringar på när nya tjänster finns tillgängliga. Vi får löpande in förfrågningar från företag som söker nya talanger så håll utkik!`
    },
    {
      text: `Är du nyfiken på att höra mer om hur processen går till? Kontakta oss för mer information!`
    },
    { bold: `Varmt välkommen till oss önskar teamet på Uppstuk!` }
  ],

  [
    {
      titleCategory: "Söka jobb",
      title: "Tips: Så skriver du ett bra CV",
      author: "Niklas Fröling",
      date: "22:a oktober, 2019",
      picture: PictureBlog3,
      link: "/nyheter/cv"
    },
    {
      quote: `Börjar det bli tomt i plånboken? På tiden att söka jobb tänker du! Du slår upp datorn och slösurfar ett tag i försök att hitta något lockande jobb. Efter en del letande hittar du till slut en intressant annons med rätt antal timmar där kompetensen de söker passar perfekt med din profil. Dags att skicka in din ansökan! Först går det fort och du fyller snabbt i information om dina studier, din erfarenhet och kompetens. En snygg bild har du redan hunnit tagit och laddar in i dokumentet. Dock känner du dig fortfarande inte helt nöjd med ditt cv. Vad ska du göra nu?`
    },
    {
      text: `Idag är det många som tävlar på samma villkor om arbete och det gäller att sticka ut för att komma vidare i processen. Vi vet själva hur svårt det kan kännas att komma igång och få till den där perfekta ansökan som är tillräckligt rolig, proffsig och inte för lång så att företaget väljer just dig. Med erfarenhet av både jobbsökande och rekryterande part har vi nedan listat våra bästa tips för att hjälpa dig att göra ditt cv så bra som möjligt!`
    },
    { header: `Fem tips till ditt perfekta CV` },
    { bold: `1. Stavning` },
    {
      text: `Ett cv med stavfel ger inget bra första intryck. Läs därför igenom ditt cv flera gånger innan utskick för att undvika onödiga felstavningar.`
    },
    { bold: `2. Struktur` },
    {
      text: `Det är viktigt att ditt cv är lättöverskådligt med en tydlig struktur. På så sätt får rekryteraren snabbt en överskådlig bild över dina kunskaper och erfarenheter är viktigt att ditt cv har en enkel och logisk struktur på informationen. Dela upp ditt cv i rubriker (t.ex. Arbetslivserfarenhet, Utbildning, Meriter och Referenser). Använd omvänd kronologisk ordning när du listar dina jobb så att din utveckling syns tydligt. Är du nyexaminerad kan du sätta din utbildning högst upp. Kom även ihåg att skriva med dina kontaktuppgifter väl synligt eftersom det är viktigt för arbetsgivaren att lätt kunna komma i kontakt med dig!`
    },
    { bold: `3. Layout ` },
    {
      text: `Anpassa alltid layouten på ditt cv efter jobbet du söker. Det kan kännas lockande att skriva hela sitt cv i versaler eller lägga på en massa färg, men ha i åtanke att smaken är olika och att ett stilrent cv aldrig är fel tänk på att smaken är olika och att ett stilrent cv aldrig är fel. Använd gärna en proffsig bild om du väljer att ha ett foto.`
    },
    { bold: `4. Skriv inte bara titlar` },
    {
      text: `Det är vanligt att skriva ner arbetsplats och titel i sitt cv, men många glömmer att skriva med vad rollen faktiskt innebar. Fundera på vad som hade fått dig att kalla någon till en intervju och skriv med detta. Exempelvis bör du beskriva rollen, vad du har lärt dig och vilka resultat du har uppnått. Försök även skriva med mätbara resultat såsom ”ökade omsättningen med X %”. Om du under din tidigare anställning har deltagit i kurser eller projekt som kan vara en fördel för det jobb du söker bör du absolut skriva med även detta. Fundera på vad som hade fått dig att kalla någon till intervju.`
    },
    { bold: `5. Längd på ditt CV` },
    {
      text: `Många säger max 2 sidor, men vi känner att regeln snarare borde vara att ett cv kan vara så långt som dess innehåll motiverar. Försök fånga uppmärksamhet med punktlistor istället för långa meningar. Du behöver inte sålla bort viktiga erfarenheter bara för att de inte får plats, men inte heller fylla ut med oväsentligheter. Exempelvis är det kanske inte så relevant för en tjänst som webbutvecklare att nämna att du jobbade extra som servitris i två månader för 15 år sedan.`
    },
    { bold: `Vad gör jag om jag har luckor i mitt cv?` },
    {
      text: `Det kan finnas många anledningar till att du inte har arbetat under en period och det är helt ok. Luckor behöver inte vara ett hinder för din karriär – bara du hanterar dem på rätt sätt. Berätta vad du gjort och varför du valt att göra det: Vänd dina luckor till en fördel – kanske har du sökt flera jobb men inte blivit anställd, men pluggade en kurs för att öka dina chanser för att få jobb? Skriv det då! Det är även vanligt att man sållar bort tidigare erfarenheter som inte är direkt relevanta för tjänsten, och då kan man tydliggöra detta för rekryteraren genom att ha rubriken “Relevant arbetslivserfarenhet”. `
    },
    { bold: `Lycka till önskar teamet på Uppstuk!` }
  ],

  [
    {
      titleCategory: "Söka jobb",
      title: "Så funkar processen",
      author: "Niklas Fröling",
      date: "13 oktober 2019",
      picture: PictureBlog4,
      link: "/nyheter/hur-det-fungerar"
    },
    {
      quote: `På Uppstuk matchar vi din unika kompetens med intressanta företag inom flera olika branscher. Vi kan hjälpa dig oavsett om du befinner dig mitt i studierna, hunnit ta examen eller redan har foten ute i arbetslivet. Hos oss har du möjligheten att arbeta som konsult men också hitta drömjobbet via en direktrekrytering. Är du nyfiken på att veta mer om hur vi går tillväga? Nedan har vi samlat en steg för steg förklaring om hur processen går till! Läs gärna även om vad det innebär att arbeta som konsult via oss och hur det skiljer sig från en direktrekytering nedan.`
    },
    {
      text: `Rekryteringsprocessen startar så fort du skickat in en ansökan till ett jobb du är intresserad av. Vi går igenom ansökningarna löpande, och om du är en av de kandidater som bäst matchar tjänsten, kontaktar vi dig för ett kort telefonmöte. Går du vidare i rekryteringsprocessen följer sedan ett möte hos oss, referenstagning och som ett sista steg – ett möte hos företaget. Nedan guidar vi dig genom de olika stegen!`
    },
    {
      bold: `1. Registrera dig eller logga in på ditt befintliga konto och sök ett jobb som intresserar dig.`
    },
    {
      text: `Vi rekommenderar alltid att du söker ett jobb så snart som möjligt eftersom vi går igenom ansökningar löpande i den ordning de kommer in. Sen är det bara att hålla tummarna för att du blir kallad på ett möte!`
    },
    {
      text: `Läs mer om våra tips för ett bra cv innan du skickar in din ansökan.`
    },
    { bold: `2. Första urvalet` },
    {
      text: `Vi går igenom samtliga ansökningar, och kontaktar de kandidater som bäst matchar tjänstens kravprofil. Är du inte en av de som gått vidare får du återkoppling per mail. Vårt mål är att återkoppla snarast möjligt, dock senast inom två veckor efter att du har skickat in din ansökan.`
    },
    { bold: `3. Telefonmöte` },
    {
      text: `Om du är en av de sökande som gått vidare i rekryteringen så kontaktar vi dig för en kortare avstämning per telefon. Syftet med samtalet är att få veta lite mer om dig helt enkelt. Har du blivit uppringd får du återkoppling via telefon eller mail inom en vecka gällande nästa steg i rekryteringsprocessen.`
    },
    { bold: `4. Möte hos Uppstuk` },
    {
      text: `Om du är en av de som vi anser är mest lämpade för tjänsten så kontaktar vi dig för ett personlig möte där du får träffa en av våra rekryterare. Vi vill inte bara veta mer om dina kompetenser och erfarenheter. Vi är även intresserade av vem du är och vad du har för drömmar och ambitioner för att vi ska kunna hjälpa dig att hitta en tjänst och en arbetsgivare som passar just dig. Läs mer om hur du förbereder dig inför intervjun.`
    },
    { bold: `5. Möte hos kund` },
    {
      text: `Tillsammans med kunden går vi igenom din profil, och diskuterar hur denna matchar företagets förväntning. Kunden beslutar därefter om du går vidare för ett personligt möte. Innan mötet kommer du att få stöttning från Uppstuks rekryterare för att på bästa möjliga sätt förbereda dig inför mötet hos kund. På mötet kommer även din rekryterare att närvara. Vi håller löpande kontakt med dig under hela rekryteringsprocessen, så att du ständigt vet hur långt vi har kommit i processen och om du fortfarande är aktuell för tjänsten. `
    },
    { bold: `5. Referenser` },
    {
      text: `Är du en av slutkandidaterna så kontaktar vi alltid de referenser som du angett i din ansökan.`
    },
    { bold: `6. Anställning` },
    {
      text: `Om allt går vägen blir det du som erbjuds tjänsten, och då är det dags att skriva avtal. Är det ett konsultuppdrag skrivs avtalet mellan dig och Uppstuk, och du får en dedikerad konsultchef som finns till för att stötta dig under ditt uppdrag. Är det istället en direktrekrytering skriver du avtal direkt med företaget, men vi finns kvar som stöttning under de första 6 månaderna i din anställning.`
    },
    { bold: `Varmt välkommen till oss önskar teamet på Uppstuk!` }
  ],

  [
    {
      titleCategory: "Söka jobb",
      title: "Tips inför intervjun!",
      author: "Malin Johansson",
      date: "1:a oktober, 2019",
      picture: PictureBlog5,
      link: "/nyheter/tips-for-intervju"
    },
    {
      quote: `Intervjun är ett bra tillfälle för dig som kandidat och för företaget att lära känna varandra. Du får en chans att berätta mer om dig själv, dina erfarenheter, och varför du passar för just den här tjänsten. Du kommer även få svara på frågor om dina meriter och samtidigt själv ställa frågor. En intervju börjar oftast med att rekryteraren berättar om företaget, den lediga tjänsten, och redogör för strukturen för intervjun. Sedan är det dags för att fokusera på dig!`
    },
    { header: `Våra bästa tips inför, under och efter intervjun` },
    { bold: `1. Var påläst och förberedd` },
    {
      text: `När du blir kallad till en intervju gäller det att komma väl förberedd. Du kommer garanterat att få frågor rörande företaget och vet du knappt vad företaget sysslar med ger du inte något vidare bra första intryck. Läs gärna igenom annonsen och ditt CV igen. Intervjuarens frågor utgår ofta från vad du har skrivit. Skaffa dig även god kunskap om företaget genom att läsa på om det på deras hemsida.`
    },
    { bold: `2. Lyssna, reflektera, & visa intresse` },
    {
      text: `Även om det inte finns någon exakt mall över hur en intervju ska gå till, finns det ändå vissa moment du kan förvänta dig. Tänk noga igenom varför du vill ha jobbet och vad du kan bidra med. Fundera även igenom hur du presenterar dig själv: vad är relevant och viktigt för tjänsten du sökt? Var förberedd på att kunna motivera dina svar.`
    },
    {
      text: `Var inte rädd för att be intervjuaren förtydliga en fråga som du inte riktigt förstår. Kom även ihåg att vara så konkret som möjligt när du svarar på frågor om hur du agerat i olika sammanhang: beskriv situationen, vad du gjorde och vad resultatet blev.`
    },
    { bold: `3. Visa självinsikt` },
    {
      text: `Öva dig också på att besvara frågor som: vad brinner du för, vad gör dig omotiverad, vilka resultat har du uppnått, vad är dina mål, vad kan du bidra med, vad vill du bli bättre på?  `
    },
    {
      text: `Om du får frågor om dina mindre bra sidor, var beredd på att lämna några exempel. Här är det viktigt att framhäva vad du lärt dig av eventuella bakslag. Glöm inte heller under intervjun att berätta om initiativ du har tagit på tidigare arbetsplatser eller studier som lett till positiva resultat. Kanske du gjort ett grupparbete i en grupp där kommunikationen inte alls fungerat, vilket gjorde dig frustrerad men du lyckades hantera den dåliga kommunikationen på ett sätt som förbättrade hela teamets samarbete? Du kanske inte alltid får sådana frågor, men att ha tänkt igenom möjliga svar kommer att göra dig säkrare.`
    },
    {
      text: `Prata heller inte illa om tidigare arbetsgivare. Säg snarare att ”min chef och jag hade olika uppfattning” än att ”chefen var hemsk”.`
    },
    { bold: `4. Lugna dina nerver` },
    {
      text: `Intervjuer är tyvärr ofta en onaturlig situation, speciellt om man sitter i ett litet rum på varsin sida av ett bord, men genom att närma sig intervjuerna på rätt sätt kan du undvika den allra värsta nervositeten.`
    },
    {
      text: `Kom ihåg att det är helt normalt att vara nervös! Intervjuaren har någon gång varit i precis samma situation som du själv är i nu. Att komma väl förberedd till en intervju är självklart det allra viktigaste och ett bra sätt att undvika överraskningar. Ta även gärna reda på t.ex. företagets vision och mission, produkter/tjänster, medarbetare, företagskultur mm., för att kunna använda denna information under intervjun som belägg för varför just du vill jobba hos företaget.`
    },
    { bold: `5. Klädsel` },
    {
      text: `Tänk på att anpassa din klädsel efter tjänsten du söker. Du kan alltid ringa företaget innan mötet för att fråga om de har någon klädkod. Det är dock viktigt att du känner dig bekväm i de kläder du har på dig eftersom detta kommer garanterat att få dig att känna dig mer avslappnad under intervjun!`
    },
    { bold: `6. Ställ frågor och anteckna` },
    {
      text: `En intervju handlar inte bara om att intervjuaren ska få svar på sina frågor, utan minst lika viktigt är det att du får svar på dina frågor! Fokusera på saker som är viktiga för dig och samtidigt visar på ditt intresse för företaget och tjänsten. Du kan t.ex. ställa följande frågor:`
    },
    { text: `– Hur ser teamet ut?` },
    { text: `– Vilka förväntningar ställs på rollen?` },
    { text: `– Hur mäts min prestation?` },
    { text: `– Vad är era framtidsplaner?` },
    { text: `– Vilka utmaningar har ni just nu och hur hanterar ni dem?` },
    { text: `– Vem kommer jag rapportera till?` },
    { text: `– Vilka egenskaper är viktiga för rollen?` },
    { text: `– Hur ser er företagskultur ut?` },
    { text: `– Vilka möjligheter finns för kompetensutveckling osv?` },
    { text: `– Har ni haft många sökanden? Vad är nästa steg i processen?` },
    { text: `– Vad tycker ni om min profil för tjänsten?` },
    { text: `– Är det något annat ni ännu funderar på?` },
    { text: `– Hur ser rekryteringsprocessen ut? Vad är nästa steg?` },
    { bold: `7. Efter intervjun` },
    {
      text: `Efter intervjun får du gärna även skicka ett e-postmeddelande där du tackar för ett trevligt möte.`
    },
    { bold: `Lycka till önskar teamet på Uppstuk! ` }
  ],

  [
    {
      titleCategory: "Företag",
      title: "Få hjälp med rekrytering",
      author: "Niklas Fröling",
      date: "16 september 2019",
      picture: PictureBlog6,
      link: "/nyheter/fa-hjalp-med-rekrytering"
    },
    {
      bold: `Behöver du hjälp med att rekrytera? Ta hjälp av vårt stora nätverk för akademiker och studenter! När du anlitar oss för rekrytering garanterar vi både hög kvalitet och personligt bemötande genom hela processen.`
    },
    {
      text: `Med hjälp av en erfaren rekryterare får du hjälp att hitta rätt person till just er organisation, oavsett nivå.`
    },
    { header: `I rekrytering via Uppstuk ingår:` },
    {
      text: `– Behovsanalys, kravprofilstagning och rådgivning av en rekryterare`
    },
    { text: `– Offert och avtalsskrivning` },
    {
      text: `– Utformning av annons samt annonsering på Uppstuks hemsida och sociala medier, såsom LinkedIn, Facebook och andra jobbsajter`
    },
    {
      text: `– Screening, telefonintervjuer, djupintervjuer och övrig kommunikation och hantering av kandidater`
    },
    { text: `– Presentation av kandidater` },
    { text: `– Referenstagning på slutkandidat` },
    {
      text: `– Uppföljning av rekrytering månad 3 och 6 efter anställning, och kvalitetsgaranti 2 månader`
    },
    { header: `Är ditt företag i behov av personal?` },
    {
      text: `Kontakta oss så hör vi av oss och pratar med om hur ert behov av personal ser ut, oavsett om en rekrytering eller konsultinhyrning är aktuell nu eller i framåt.`
    }
  ],

  [
    {
      titleCategory: "Företag",
      title: "Hyr en konsult",
      author: "Niklas Fröling",
      date: "12 september 2019",
      picture: PictureBlog7,
      link: "/nyheter/hyr-en-konsult"
    },
    {
      quote: `Uppstuk är ett bemannings- och rekryteringsföretag som matchar företag med vårt breda kandidatnätverk av akademiker. Vi är verksamma inom flera områden som bland annat IT, Life science och ekonomi, och kan erbjuda både juniora och seniora profiler enligt dina önskemål och behov. Vår tjänst flexkompetens hjälper dig vid arbetstoppar, med deltidstjänster och heltid.`
    },
    {
      text: `Vi på Uppstuk har en långtgående historia av att hjälpa företag hitta sina nya talanger och fördelarna är många. Genom att hyra in en konsult innan du väljer att anställa får du möjlighet att prova konsultens förmåga för jobbet i praktiken. När du sedan har hittat rätt, har du möjligheten att överta anställningen.`
    },
    {
      text: `En annan fördel är att du via vår bemanningslösning endast betalar för de timmar du nyttjar samt att du uppnår maximal flexibilitet i din verksamhet. Det innebär att vi exempelvis hanterar tidrapportering, löneadministration, betalar semesterersättning, arbetsgivaravgift och sjuklön. Du slipper det administrativa arbetet, och kan fokusera på din verksamhet.`
    },
    {
      text: `När du bemannar via Uppstuk är det viktigt för oss att du känner dig trygg. Vi värdesätter våra kunder högt och eftersträvar att tillsammans hitta den lösning som funkar bäst för er. Oavsett om du önskar att hyra in konsulter för kortare period under arbetstoppar, på deltid eller heltid hjälper vi dig via vår tjänst flexkompetens. Hos oss har ni också en tydlig kontaktperson som ni alltid kan vända er till. Detta stöd finns kvar under hela uppdragets gång och även efteråt.`
    },
    {
      text: `Vi är specialister på bemanning, när du söker kvalificerad kompetens kan vi hjälpa dig. Kontakta oss så berättar vi mer!`
    }
  ],

  [
    {
      titleCategory: "Söka jobb",
      title: "Uppstuk är starten på din karriär",
      author: "Malin Johansson",
      date: "2:a september, 2019",
      picture: PictureBlog8,
      link: "/nyheter/uppstuk-ar-starten-pa-din-karriar"
    },
    {
      bold: `Har du höga ambitioner och mål med din karriär? Vi matchar din unika kompetens från din universitetsutbildning med attraktiva företag på arbetsmarknaden. Som konsult kommer du samla på dig arbetslivserfarenhet, stärka ditt CV och bygga upp ett större professionellt kontaktnät på de arbetsplatser du jobbar på. Att jobba som konsult hos oss kan vara din genväg till drömjobbet; många av våra konsulter blir erbjudna en fast tjänst hos de företag de har jobbat via oss.`
    },
    {
      text: `Våra konsultuppdrag varierar i omfattning från några timmar i veckan till heltid. Ta första klivet genom att registrera dig i vårt kandidatnätverk eller genom att söka något av våra lediga jobb!`
    },
    {
      text: `Hittar du inte ditt drömjobb bland våra lediga tjänster? Ligg steget före och registrera dig enkelt i vårt nätverk genom att trycka på Connect. Vi söker löpande efter talanger till våra kunder!`
    },
    { text: `Eller vill du hellre bli direktrekryterad till kund?` },
    { header: `Bli rekryterad till ett företag` },
    {
      text: `Vi hjälper företag att hitta sina framtida talanger genom att sköta hela, eller delar av rekryteringsprocessen. Det innebär att det är vi på Uppstuk som intervjuar dig, sköter kommunikationen, och tar referenser. Därefter presenteras du för arbetsgivaren, och om du erbjuds tjänsten sker avtalsskrivning direkt med företaget. För att säkerställa att du trivs hos din nya arbetsgivare finns vi även kvar som stöttning under de första 6 månaderna i din anställning.`
    },
    { header: `Bli konsult redan under studietiden!` },
    {
      text: `Att jobba extra under studietiden är ett utmärkt tillfälle att skaffa erfarenhet och göra dig mer attraktiv på arbetsmarknaden när du tagit examen. Uppstuk erbjuder kvalificerade deltidsjobb till dig som vill få relevant erfarenhet inom ditt utbildningsområde.`
    },
    { header: `Vad innebär det att jobba som konsult?` },
    {
      text: `Som konsult via Uppstuk blir du anställd av oss men jobbar på något av de företag vi samarbetar med. Du får en dedikerad konsultchef som finns till för att stötta dig under ditt uppdrag. I många fall leder konsultuppdragen till förlängning och/eller anställning direkt hos företaget.`
    },
    { bold: `Är du redo för att hitta ditt drömjobb?` }
  ],
];

export const contactForm = {
  titleOne: "Kontakt",
  titleTwo: "Kontakta oss gärna!",
  placeholderOne: "Namn",
  placeholderTwo: "E-mail",
  placeholderThree: "Telefon",
  placeholderFour: "Ditt meddelande",
  button: "Skicka",
  success: "Ditt mail har skickats!",
  fail: "Ojdå, något gick fel. Vänligen försök igen!",
  picture: PictureContactForm
};

export const footer = {
  column1: {
    cn: "column1",
    header: { cn: "title" },
    text1: { cn: "footer", text: "Kandidat" },
    text2: {
      cn: "footer",
      button: "Sök jobb",
      link: "/jobb"
    },
    text3: {
      cn: "footer",
      button: "Connect",
      link: "https://uppstuk.teamtailor.com/connect"
    },
    text4: {
      cn: "footer",
      button: "Registrera CV",
      link: "https://uppstuk.teamtailor.com/connect"
    },
    text5: {
      cn: "footer",
      button: "Karriärtips",
      link: "/nyheter"
    }
  },
  column2: {
    cn: "column1",
    header: { cn: "title" },
    text1: { cn: "footer", text: "Företag" },
    text2: {
      cn: "footer",
      button: "Våra tjänster",
      link: "/foretag"
    },
    text3: {
      cn: "footer",
      button: "Hyr in konsult",
      link: "/foretag"
    },
    text4: {
      cn: "footer",
      button: "Rekrytering",
      link: "/foretag"
    }
  },
  column3: {
    cn: "column3",
    header: { cn: "title" },
    text1: { cn: "footer", text: "Uppstuk" },
    text2: {
      cn: "footer",
      button: "Om Uppstuk",
      link: "/om-uppstuk"
    },
    text3: {
      cn: "footer",
      button: "Kontakta oss",
      link: "/kontakt"
    },
    facebook: {
      link: {
        as: "a",
        cn: "picture",
        href: "https://www.facebook.com/Uppstuk"
      },
      picture: {
        as: "img",
        cn: "pictureImg",
        src: PictureFacebook,
        alt: "Facebook link"
      }
    },
    instagram: {
      link: {
        as: "a",
        cn: "picture",
        href: "https://www.instagram.com/uppstuk/?hl=sv"
      },
      picture: {
        as: "img",
        cn: "pictureImg",
        src: PictureInstagram,
        alt: "Instagram link"
      }
    },
    linkedIn: {
      link: {
        as: "a",
        cn: "picture",
        href: "https://www.linkedin.com/company/1756480/"
      },
      picture: {
        as: "img",
        cn: "pictureImg",
        src: PictureLinkedIn,
        alt: "LinkedIn link"
      }
    }
  },
  column4: {
    cn: "column4",
    header: { cn: "logo", as: Link, to: "/" },
    logo: {
      cn: "logoImg",
      as: "img",
      src: PictureLogo,
      alt: "Company logo"
    },
    text2: {
      cn: "footer",
      button: "Orgnr. 55 68 35 - 3840",
      link: "https://www.allabolag.se/5568353840/uppstuk-ab"
    },
    text3: {
      cn: "footer",
      button: "Dag Hammarskjölds Väg 26",
      link:
        "https://www.google.com/maps/place/Uppstuk/@59.8441222,17.6369365,17z/data=!3m1!4b1!4m5!3m4!1s0x465fc95f689b15a1:0xac1262b5ca56740d!8m2!3d59.8441222!4d17.6391252"
    },
    text4: {
      cn: "footer",
      button: "752 37 Uppsala",
      link:
        "https://www.google.com/maps/place/Uppstuk/@59.8441222,17.6369365,17z/data=!3m1!4b1!4m5!3m4!1s0x465fc95f689b15a1:0xac1262b5ca56740d!8m2!3d59.8441222!4d17.6391252"
    },
    text5: "info@uppstuk.se",
    text6: "© 2019 Uppstuk AB",
    link1: "/"
  }
};
